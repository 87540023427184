import React from 'react';
import katex from 'katex';
import { InlineMath } from 'react-katex';
import NonlinearNewton from '../../components/method/Newton';  // Adjust path as necessary
import flowchart from '../../assets/images/Flowchart_Algoritma_Newton_Raphson_Method.png';

import galatmutlak from "../../assets/images/tabcontent/galatmutlak.png"
import galatrelatifer from "../../assets/images/tabcontent/galatrelatifer.png"
import galatrelatif from "../../assets/images/tabcontent/galatrelatif.png"
import galatglobal from "../../assets/images/tabcontent/galatglobal.png"
import a from "../../assets/images/tabcontent/a.png"
import b from "../../assets/images/tabcontent/b.png"
import c from "../../assets/images/tabcontent/c.png"
import d from "../../assets/images/tabcontent/d.png"
import o from "../../assets/images/tabcontent/o.png"
import p from "../../assets/images/tabcontent/p.png"
import q from "../../assets/images/tabcontent/q.png"
import r from "../../assets/images/tabcontent/r.png"
import s from "../../assets/images/tabcontent/s.png"
import t from "../../assets/images/tabcontent/t.png"

function TabContent({ activeTab }) {
    // Persamaan matematika
    const equation = 'f\'(x_0) \\neq 0';
    const equation2 = '\\frac{dy}{dx} = anx^{n-1}';
    const equation3 = 'x_{1} = x_{0} - \\frac{f(x_{0})}{f\'(x_{0})}';
  
    // Render persamaan menjadi HTML
    const html = katex.renderToString(equation);
    const html2 = katex.renderToString(equation2);
    const html3 = katex.renderToString(equation3);

    const listStyle = {
      listStyleType: 'lower-alpha'
    };
  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return (
          <div className="tab-pane fade show active" id="course-pills-1" role="tabpanel" aria-labelledby="course-pills-tab-1">
            <h5 className="mb-3">Metode Numerik</h5>
            <p>Metode numerik adalah suatu pendekatan matematika yang menggunakan teknik-teknik numerik untuk memecahkan masalah matematika yang melibatkan perhitungan atau analisis secara komputasional. Metode/cara yang ditempuh dengan melibatkan data berupa bilangan/angka tertentu dikenal dengan sebutan metode numerik (ada juga yang menyebutnya dengan metode heuristik).</p>
            <p>Metode ini sangat berguna ketika solusi analitik dari suatu masalah tidak dapat ditemukan dengan mudah atau tidak memungkinkan untuk diselesaikan secara eksak. Dalam metode ini yang paling utama ditekankan adalah kecepatan konvergensi sebuah cara atau metode dan analisis galat atau eror. Termasuk didalamnya ada pembuktian suatu metode dan analisis batas-batas galat atau eror.</p>
            <p><b>Ada beberapa metode numerik umumnya digunakan, di antaranya:</b></p>
            <ul>
				<li>Metode Iteratif</li>
				<li>Metode Interpolasi</li>
				<li>Metode Numerik untuk Turunan dan Integral</li>
				<li>Metode Matriks</li>
				<li>Metode Kombinatorial</li>
				<li>Metode Optimasi</li>
			</ul>
			<h5 className="mb-3">Metode Iteratif</h5>
			<p>Metode iteratif merupakan pendekatan dalam matematika yang melibatkan serangkaian langkah yang diulang-ulang untuk mendekati solusi dari suatu masalah. Dalam metode ini, kita tidak langsung mendapatkan solusi eksak, melainkan mendekati solusi tersebut melalui proses iterasi. Inti dari metode iteratif adalah membuat estimasi awal yang mendekati solusi sebenarnya, kemudian melakukan perhitungan berulang berdasarkan estimasi tersebut untuk mendekati solusi yang lebih akurat. Setiap iterasi menghasilkan perkiraan baru, dan proses iterasi berlanjut hingga solusi yang cukup dekat dengan solusi sebenarnya ditemukan atau hingga kriteria tertentu berhenti terpenuhi.</p>
			<p>Dalam implementasinya, metode iteratif dapat berbeda-beda tergantung pada jenis masalah yang dihadapi. Namun, secara umum, langkah-langkah dalam metode iteratif melibatkan proses perhitungan berulang menggunakan formula atau aturan tertentu yang dirancang untuk mendekati solusi akhir. Selain itu, analisis galat juga penting dalam mengevaluasi kualitas solusi yang diperoleh dari metode iteratif.</p>
			<p><b>Berikut adalah beberapa jenis metode iteratif yang umum digunakan:</b></p>
			<ul>
				<li>Metode Newton-Raphson</li>
				<li>Metode Bagi Dua (Bisection Methods)</li>
				<li>Metode Secant</li>
				<li>Metode Posisi Palsu (False Position Methods)</li>
			</ul>
			<h5 className="mb-3">Analisis Galat</h5>
			<p>Ketika metode numerik digunakan untuk menyelesaikan suatu masalah biasanya dimulai dengan menetapkan sebarang nilai awal. Dengan nilai awal tersebut selanjutnya langkah-langkah komputasi numerik dilakukan sehingga diperoleh suatu penyelesaian yang diinginkan baik berupa bilangan atau model hampiran. </p>
			<p>Galat terbagi menjadi 2 tipe, yaitu:</p>
			<ol>
				<li>Galat mutlak adalah selisih numerik antara besar nilai sebenarnya dengan nilai aproksimasinya. Jadi, bila x besar nilai yang sebenarnya, dan 1X nilai hampiranya maka galat mutlak (absolut error) Ea didefinisikan dengan formula </li>
				<br></br><img src={galatmutlak}></img>
				<li>Galat relatif Er didefinisikan dengan formula  
				<br></br><img src={galatrelatifer}></img>
					<br></br>Sedangkan persentase galat relatif dihitung dari galat relatif yang diberikan dalam bentuk</li>
					<br></br><img src={galatrelatif}></img>
				<li>Galat Global yakni, Misal U = f (X1, X2, …, Xn) adalah fungsi dengan variabel banyak Xi = (1,2, … ,n) dan misalkan galat dari tiap Xi adalah Delta Xi . Galat Delta U dari U diberikan dalam bentuk formula</li>
				<br></br><img src={galatglobal}></img>
				
			</ol>
			<h5 className="mt-4">Apa yang akan diselesaikan</h5>
            <ul className="list-group list-group-borderless mb-3">
              {/* List of learning outcomes */}
              <li className="list-group-item h6 fw-light d-flex mb-0"><i className="fas fa-check-circle text-success me-2"></i>Fungsi Turunan</li>
			  <li className="list-group-item h6 fw-light d-flex mb-0"><i className="fas fa-check-circle text-success me-2"></i>Langkah Iterasi</li>
			  <li className="list-group-item h6 fw-light d-flex mb-0"><i className="fas fa-check-circle text-success me-2"></i>Analisis Galat</li>
			  <li className="list-group-item h6 fw-light d-flex mb-0"><i className="fas fa-check-circle text-success me-2"></i>Grafik</li>
			  {/* Additional items */}
            </ul>
          </div>
        );
      case 'teori':
        return (
          <div className="tab-pane active" id="course-pills-2" role="tabpanel" aria-labelledby="course-pills-tab-2">
            {/* Course curriculum content */}
			<h5>Metode Newton-Raphson</h5>
			<p>Metode Newton-Raphson adalah salah satu metode iterasi yang paling efektif dalam mencari akar dari suatu persamaan nonlinear. Metode ini didasarkan pada ide dasar bahwa garis singgung pada titik tertentu dari kurva fungsi akan melintasi sumbu x di titik di mana akar fungsi berada. Dengan menggunakan pendekatan ini, kita dapat menghasilkan tebakan awal yang lebih baik untuk mendekati solusi sebenarnya. </p>
			<p>Namun, ada beberapa hal yang perlu diperhatikan ketika menggunakan Metode Newton-Raphson. Pertama, metode ini hanya akan konvergen jika tebakan awal cukup dekat dengan akar yang dicari. Jika tebakan awal terlalu jauh dari akar sebenarnya, metode ini dapat menghasilkan solusi yang salah atau bahkan tidak konvergen sama sekali.</p>
			<p>Dalam praktiknya, Metode Newton-Raphson sering digunakan dalam berbagai bidang seperti optimasi, analisis numerik, dan pemodelan matematika karena kemampuannya untuk menghasilkan solusi yang cepat dan akurat untuk masalah-masalah nonlinear.</p>
            <p><b>Ada dua pendekatan dalam menurunkan rumus metode Newton-Raphson, yaitu:</b></p>
			<ol>
				<li><b>Penurunan rumus Newton-Raphson secara geometri.</b>
				<br></br><br></br><img src={a}></img>
				<br></br><br></br>Gradien garis singgung di Xr adalah
				<br></br><img src={b} style={{width:'155px', height:'65px'}}></img>
				<br></br>atau<br></br>
				<img src={c} style={{width:'140px', height:'60px'}}></img>
				<br></br>sehingga prosedur lelaran metode Newton-Raphson adalah</li>
				<br></br><img src={d}></img>
				<br></br><li><b>Penurunan rumus Newton-Raphson dengan bantuan deret Taylor.</b>
				<br></br><img src={o}></img>
				<br></br>Uraikan f(xr+1) di sekitar xr ke dalam deret Taylor
				<br></br><br></br><img src={p}></img>
				<br></br>yang bila dipotong sampai suku orde-2 saja menjadi
				<br></br><br></br><img src={q}></img>
				<br></br><br></br>dan karena persoalan mencari akar, maka f(xr+1) = 0, sehingga</li>
				<br></br><br></br><img src={r}></img>
				<br></br><br></br>Kondisi berhenti lelaran Newton-Raphson adalah bila
				<br></br><br></br><img src={s}></img>					
				<br></br><br></br>atau bila menggunakan galat relatif hampiran
				<br></br><br></br><img src={t}></img>								
			</ol>
			{}
          </div>
        );
      case 'algoritma':
        return (
          <div className="tab-pane fade show active" id="course-pills-3" role="tabpanel" aria-labelledby="course-pills-tab-3">
            <h5 className="mb-3">Algoritma Metode Newton Raphson</h5>
            <h6 className="mb-3">FlowChart</h6>
            <img src={flowchart} alt="Flowchart Algoritma Newton Raphson Method" className="img-fluid"/>
            <br />
            <br />
            <h6 className="mb-3">Algoritma dari Flowchart</h6>
            <ol>
              <li>Input Fungsi: Pengguna memasukkan fungsi matematika dalam format tertentu yaitu hanya menerima variabel <InlineMath>x</InlineMath>.</li>
              <li>Validasi: Memvalidasi fungsi yang diinput. Jika tidak valid, tampilkan pesan kesalahan.</li>
              <li>Hitung Turunan: Menghitung turunan fungsi dengan rumus </li>
              <InlineMath>y = ax^n</InlineMath> <br/>
              <span dangerouslySetInnerHTML={{ __html: html2 }}/> <br/>
              <br/>
              <li>Set Iterasi dan <InlineMath>x</InlineMath> awal (<InlineMath>x_0</InlineMath>): Pengguna menetapkan jumlah iterasi dan nilai awal 
                  (<InlineMath>x_0</InlineMath>) dengan ketentuan <span dangerouslySetInnerHTML={{__html: html}}/>
              </li>
              <li>Periksa Validasi Input: Jika ada input yang tidak valid, menampilkan pesan kesalahan yang sesuai.</li>
              <li>
                Lakukan iterasi: Jika semua input valid, mulailah proses perulangan:
                <ul style={listStyle}>
                  <li>Tentukan <InlineMath>x_1</InlineMath> sebagai titik potong garis singgung yang melalui (<InlineMath>x_0</InlineMath>, 
                      <InlineMath>f(x_0)</InlineMath>) dengan sumbu <InlineMath>x_0</InlineMath>. 
                      Untuk menentukan <InlineMath>x_1</InlineMath> menggunakan formula <span dangerouslySetInnerHTML={{ __html: html3 }}/>
                  </li>
                  <li>Selanjutnya dengan cara yang sama ditentukan nilai dari <InlineMath>x_2</InlineMath>, <InlineMath>x_3</InlineMath>, dan seterusnya</li>
                  <li>Iterasi dapat dihentikan bila dua iterasi beruntun menghasilkan hampiran akar yang sama atau ketika <InlineMath>f(x_i) = 0</InlineMath></li>
                </ul>
              </li>
              <li>Tampilkan Hasil: Menampilkan hasil setiap iterasi, termasuk representasi grafik.</li>
            </ol>
          </div>
        );
      case 'kasus':
        return (
          <div className="tab-pane fade show active" id="course-pills-4" role="tabpanel" aria-labelledby="course-pills-tab-4">
            {/* Reviews and rating */}
            <h1 classname="mb-3">Penggunaan Metode Newthon-Raphson</h1>
			<h3 classname="mb-3"><b>Dibidang Fisika</b></h3>
			<p>Dalam fisika metode ini sering digunakan untuk menyelesaikan persamaan differensial yang muncul dalam berbagai masalah fisika, seperti dalam mekanika, elektromagnetisme, atau termodinamika.</p>
			<p>Misalnya untuk menemukan titik keseimbangan dalam sistem mekanika, atau untuk menyelesaikan persamaan untuk medan listrik dan elektrostatistika.</p>
			<p>Dalam Fisika Partikel, metode ini dapat digunakan dalam berbagai konteks, termasuk dalam penyelesaian persamaan gerak partikel subatomik atau dalam analisis medan gaya kompleks yang timbul dari interaksi pertikel.</p>

			<h3 classname="mb-3"><b>Dibidang Teknik</b></h3>
			<p>Dalam rekayasa dan teknik, metode ini dapat digunakan dalam perancangan struktur atau sistem dinamis. Misalnya, dalam analisis kestabilan struktur, untuk menemukan titik-titik kritis atau titik-titik kesetimbangan dalam sistem mekanik yang kompleks.</p>
			<p>Pada Perancangan Sistem Kontrol, metode ini digunakan untuk menyelesaikan persamaan diferensial yang muncul dalam perancangan dan analisis sistem kontrol. Ini membantu dalam menentukan parameter kontrol yang optimal untuk mencapai tujuan tertentu, seperti respons yang di inginkan dari sistem.</p>
			<p>Dalam optimasi teknik, metode ini dapat digunakan untuk mencari nilai minimum atau maksimum dari fungsi objektif dalam masalah optimasi.</p>

			<h3 classname="mb-3"><b>Dibidang Ekonomi</b></h3>
			<p>Dalam ekonomi, metode ini dapat digunakan untuk menyelesaikan sistem persamaan non linear yang muncul dalam model ekonomi matematika. Contohnya termasuk dalam analisis keseimbangan pasar, dimana kita mencari titik dimana penawaran dan permintaan sama, atau dalam penyelesaian model ekonomi dinamis.</p>
			<p>Dalam analisis model ekonomi dinamis, metode ini dapat digunakan untuk menyelesaikan sistem persamaan diferensial yang menggambarkan evolusi variabel ekonomi dari waktu ke waktu. Ini membantu dalam memahami dinamika jangka panjang dari sistem ekonomi dan meramalkan perilaku masa depan.</p>
			<p>Metode ini juga dapat digunakan untuk menentukan tarif optimal untuk produk atau layanan berdasarkan berbagai faktor, seperti biaya produksi, permintaan pasar, atau kebijakan regulasi.</p>
          </div>
        );
      case 'soal':
        return (
          <div className="tab-pane fade show active" id="course-pills-5" role="tabpanel" aria-labelledby="course-pills-tab-5">
            <h5 className="mb-3">Contoh Soal</h5>
            <p>Temukan akar persamaan yang sebenarnya dari <InlineMath>-4x + \cos x + 2 = 0</InlineMath>, dengan metode Newton Raphson hingga empat tempat desimal, asumsikan <InlineMath>x_0 = 0.5</InlineMath></p>
            <h5 className="mb-3">Solusi dan langkah penyelesaian</h5>
            <p>Diberikan persamaan : <InlineMath>-4x + \cos x + 2 = 0</InlineMath></p>
            <p><InlineMath>x0 = 0.5</InlineMath></p>
            <p><InlineMath>f(x) = -4x + \cos x + 2</InlineMath></p>
            <p><InlineMath>f’(x) = -4 – \sin x</InlineMath></p>
            <br />
            <p><InlineMath>f(0) = -4(0) + \cos 0 + 2 = 1 + 2 = 3 &gt; 0</InlineMath></p>
            <p><InlineMath>f(1) = -4(1) + \cos 1 + 2 = -4 + 0.5403 + 2 = -1.4597 &lt; 0</InlineMath></p>
            <p>Dengan Demikian, akar terletak antara 0 dan 1 Mari kita cari perkiraan pertama.</p>
            <p><InlineMath>x_1 = x_0 – f(x_0)/f’(x_0)</InlineMath></p>
            <p><InlineMath>= 0.5 – [-4(0.5) + \cos 0.5 + 2] / [-4 – \sin 0.5]</InlineMath></p>
            <p><InlineMath>= 0.5 – [(-2 + 2 + cos 0.5)/ (-4 – sin 0.4)]</InlineMath></p>
            <p><InlineMath>= 0.5 – [\cos 0.5/ (-4 – \sin 0.5)]</InlineMath></p>
            <p><InlineMath>= 0.5 – [0.8775/ (-4 – 0.4794)]</InlineMath></p>
            <p><InlineMath>= 0.5 – (0.8775/-4.4794)</InlineMath></p>
            <p><InlineMath>= 0.5 + 0.1958</InlineMath></p>
            <p><InlineMath>= 0. 6958</InlineMath></p>
          </div>
        );
    case 'kalkulator':
        return (
          <div className="tab-pane fade show active" id="course-pills-5" role="tabpanel" aria-labelledby="course-pills-tab-5">
            {/* Calculator */}
            <NonlinearNewton />
          </div>
        );
      case 'faqs':
		return (
			<div className="tab-pane fade show active" id="course-pills-5" role="tabpanel" aria-labelledby="course-pills-tab-5">
				<h5 className="mb-3">Frequently Asked Questions Newton-Raphson Method</h5>
				<div className="accordion accordion-flush" id="accordionExample">
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingOne">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
								<span className="text-secondary fw-bold me-3">01</span>  
								<span className="fw-bold">Dibidang apa saja metode Newton-Raphson digunakan?</span> 
							</button>
						</h2>
						<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
							<div className="accordion-body pt-0">
								Metode Newton-Raphson adalah teknik yang efisien untuk menyelesaikan persamaan secara numerik. Ini memberikan pendekatan yang lebih baik dalam hal solusi. 
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingTwo">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
								<span className="text-secondary fw-bold me-3">02</span>  
								<span className="fw-bold">Manakah rumus yang benar dari metode Newton-Raphson?</span> 
							</button>
						</h2>
						<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
							<div className="accordion-body pt-0">
								Untuk mencari akar dari persamaan f(x) = 0:
								<ol>
									<li>Pilih nilai awal x0 yang dekat dengan akar yang dicari.</li>
									<li>Iterasikan dengan rumus:
										x(n+1) = x(n) - f(x(n)) / f'(x(n))
									</li>
									<li>Ulangi langkah 2 sampai mencapai toleransi yang diinginkan atau konvergen.</li>
								</ol>
								Di mana:
								<ul>
									<li>x(n) adalah nilai perkiraan akar pada iterasi ke-n.</li>
									<li>f(x(n)) adalah nilai fungsi pada x(n).</li>
									<li>f'(x(n)) adalah turunan fungsi pada x(n).</li>
								</ul>
								Metode ini akan konvergen jika nilai awal yang dipilih cukup dekat dengan akar yang dicari dan turunan fungsi tidak nol di sekitar akar tersebut.
								Harap diingat bahwa implementasi metode ini dapat bervariasi tergantung pada bahasa pemrograman dan konteks penggunaannya.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingThree">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
								<span className="text-secondary fw-bold me-3">03</span>  
								<span className="fw-bold">Apakah metode Newton-Raphson selalu konvergen?</span> 
							</button>
						</h2>
						<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
							<div className="accordion-body pt-0">
								Tidak, metode Newton-Raphson tidak selalu konvergen. Ini berarti tidak selalu dapat menjamin bahwa kondisi terpenuhi. Namun, metode ini gagal ketika \(f'(x)\) sama dengan 0. 
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingFour">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
								<span className="text-secondary fw-bold me-3">04</span>  
								<span className="fw-bold">Bagaimana nilai awal dari metode Newton-Raphson?</span> 
							</button>
						</h2>
						<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
							<div className="accordion-body pt-0">
								Nilai awal yang dipilih dekat dengan akar tunggal maka akarnya terletak pada sekitar atau sama dengan akar tunggal.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingFive">
							<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
								<span className="text-secondary fw-bold me-3">05</span>  
								<span className="fw-bold">Apa kelemahan dari metode Newton-Raphson</span> 
							</button>
						</h2>
						<div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
							<div className="accordion-body pt-0">
								Dua kekurangan utama dari metode Newton-Raphson adalah:<br/>
								<ul>
									<li>Keharusan untuk memulai literasi cukup dekat dengan nol yang dicari agar mencapai konvergensi.</li>
									<li>Untuk menghitung invers dari turunan pada setiap langkah iteratif.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
      default:
        return <div>Select a tab</div>;
    }
  };

  return (
    <div className="tab-content pt-2" id="course-pills-tabContent">
      {renderContent()}
    </div>
  );
}

export default TabContent;
