import React from 'react';

function TabNav({ activeTab, setActiveTab }) {
  const tabs = [
    { id: 'overview', title: 'Pengenalan' },
    { id: 'teori', title: 'Teori Dasar' },
    { id: 'algoritma', title: 'Algoritma' },
    { id: 'kasus', title: 'Penggunaan' },
    { id : 'soal', title: 'Contoh Soal'},
    { id: 'kalkulator', title: 'Kalkulator'},
    { id: 'faqs', title: 'FAQs' }
  ];

  return (
    <ul className="nav nav-pills nav-tabs-line pt-0" id="course-pills-tab" role="tablist">
      {tabs.map(tab => (
        <li className="nav-item me-2 me-sm-4" role="presentation" key={tab.id}>
          <button
            className={`nav-link mb-2 mb-md-0 ${activeTab === tab.id ? 'active' : ''}`}
            id={`course-pills-tab-${tab.id}`}
            type="button"
            role="tab"
            aria-controls={`course-pills-${tab.id}`}
            aria-selected={activeTab === tab.id ? 'true' : 'false'}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default TabNav;
