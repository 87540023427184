import React from 'react';
import PageIntro from './PageIntro';
import PageContent from './PageContent';

function Main() {
  return (
    <main>
      <PageIntro />
      <PageContent />
    </main>
  );
}

export default Main;
