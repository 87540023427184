import React from 'react';

const Header = () => {
    return (
        <header className="navbar-light navbar-sticky">
            <nav className="navbar navbar-expand-xl">
                <div className="container">
                    <a className="navbar-brand" href="index.html">
                        <h1 className="light-mode-item">Numerical Method</h1>
                    </a>
                    <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-animation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>
                    <div className="navbar-collapse w-100 collapse" id="navbarCollapse">
                        <ul className="nav navbar-nav-scroll mx-auto">
                        </ul>
                        <div className="nav my-3 my-xl-0 px-4 flex-nowrap align-items-center">
                            <div className="nav-item w-100">
                                <ul className="navbar-nav navbar-nav-scroll mx-auto">
                                    <li className="nav-item"><a className="nav-link" href="docs/alerts.html">About</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="nav-item">
                        <a className="avatar avatar-sm p-0" href="https://github.com/achmadardanip/newton-rhapson-web" role="button" data-bs-auto-close="outside" data-bs-display="static" aria-expanded="false">
                            <img className="avatar-img rounded-circle" src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" alt="avatar" />
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
