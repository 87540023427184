import React from 'react';

function PageIntro() {
  return (
    <section className="bg-light py-0 py-sm-5">
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-8">
            <h6 className="mb-3 font-base bg-primary text-white py-2 px-4 rounded-2 d-inline-block">Nonlinear Equation</h6>
            <h1>Newton-Rhapson Method</h1>
            <p>Welcome to Newton-Rhapson Method, where mathematics meets the magic of algorithms!
              <br></br>We invite you to embark on a fascinating journey of discovery in the world of numerical analysis.
              <br></br>Here, you will discover efficient and accurate ways to solve a variety of mathematical problems, from simple equations to complex ones. Get ready to immerse yourself in every step, and explore intriguing concepts. Join us in exploring the boundless world of numerical methods with a passion for knowledge!</p>            
              <ul className="list-inline mb-0">
              <li className="list-inline-item h6 me-3 mb-1 mb-sm-0"><i className="fas fa-star text-warning me-2"></i>4.9/5.0</li>
              <li className="list-inline-item h6 me-3 mb-1 mb-sm-0"><i className="fas fa-user-graduate text-orange me-2"></i>12k Enrolled</li>
              <li className="list-inline-item h6 me-3 mb-1 mb-sm-0"><i className="fas fa-signal text-success me-2"></i>All levels</li>
              <li className="list-inline-item h6 me-3 mb-1 mb-sm-0"><i className="bi bi-patch-exclamation-fill text-danger me-2"></i>Last updated 22/04/2024</li>
              <li className="list-inline-item h6 mb-0"><i className="fas fa-globe text-info me-2"></i>Indonesia</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageIntro;
