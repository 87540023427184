import React, { useState } from 'react';
import TabContent from './TabContent';
import TabNav from './TabNav';

function PageContent() {
  const [activeTab, setActiveTab] = useState('overview');

  return (
    <section className="pb-0 py-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="bg-body shadow rounded-2 p-4">
              <TabNav activeTab={activeTab} setActiveTab={setActiveTab} />
              <hr />
              <TabContent activeTab={activeTab} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageContent;
