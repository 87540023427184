import React from 'react';

function Footer() {
    return (
      <footer className="bg-light text-center text-lg-start">
        <div className="container p-4">
          <h2 className="mb-4 text-center">Meet Our Team</h2>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div className="card border-0">
                <div className="card-body text-center">
                  <img src="https://media.licdn.com/dms/image/D4E03AQGESf9pJPJqYg/profile-displayphoto-shrink_800_800/0/1701787431510?e=1718841600&v=beta&t=7ULuR850keVHq99QBVpj-sdgt4Cs3KscY72SVKVs4iw" className="rounded-circle mb-3" alt="avatar" />
                  <h5 className="card-title mb-0">Achmad Ardani Prasha</h5>
                  <p className="card-text">Web Developer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div className="card border-0">
                <div className="card-body text-center">
                  <img src="https://media.licdn.com/dms/image/D4E03AQF1Xf2kDUUQHg/profile-displayphoto-shrink_800_800/0/1708498477219?e=1718841600&v=beta&t=yjLuQW7zItc5vnPr8JhyMw79CR5wJBCA_O-yo8SWP64" className="rounded-circle mb-3" alt="avatar" />
                  <h5 className="card-title mb-0">Erland Widyatamaka</h5>
                  <p className="card-text">Web Developer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div className="card border-0">
                <div className="card-body text-center">
                  <img src="https://media.licdn.com/dms/image/D5603AQE3HNb8DC4vqw/profile-displayphoto-shrink_800_800/0/1697079450118?e=1718841600&v=beta&t=uQHl7DKApmXWYH-sba7DN8x7dJ0vBb6zoBE8ca4UY48" className="rounded-circle mb-3" alt="avatar" />
                  <h5 className="card-title mb-0">Muhammad Arfan</h5>
                  <p className="card-text">Web Developer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div className="card border-0">
                <div className="card-body text-center">
                  <img src="https://media.licdn.com/dms/image/D5603AQFyAor85Q67Tg/profile-displayphoto-shrink_800_800/0/1714117165975?e=1720051200&v=beta&t=9z-8NlQnZ-agmK82XT7LP4UtRly3GQ4f-_oJvA4TSFE" className="rounded-circle mb-3" alt="avatar" />
                  <h5 className="card-title mb-0">Sabrina Laila Mutiara</h5>
                  <p className="card-text">Web Developer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

export default Footer;
