import React from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

<>
  <Helmet>
    <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="description" content="React App" />
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
	  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin=""/>
	  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap"/>

  </Helmet>
</>


import fontawesome from '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/tiny-slider/tiny-slider.css';
import GLightbox from 'glightbox/src/postcss/glightbox.css';
import './assets/vendor/choices/css/choices.min.css';
import './assets/css/style.css';
import './assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/vendor/tiny-slider/tiny-slider.js';
import GLightboxJS from 'glightbox/src/js/glightbox.js';
import './assets/vendor/choices/js/choices.min.js';
import './assets/js/functions.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
